body {
  overflow: overlay;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

.image {
  border-radius: 5px;
  max-width: 100%;
  margin: auto;
  cursor: pointer;
}

.cover {
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}

.contain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
